import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Navigation for redirection
import './LoginPage.css';

function LoginPage({ onLogin }) {
  const [licenseKey, setLicenseKey] = useState('');
  const navigate = useNavigate();

const handleSubmit = (e) => {
  e.preventDefault();

  const adminKey = process.env.REACT_APP_ADMIN_TOKEN || 'LILLEHAMMER';
  console.log('Expected Admin Key from .env: ', adminKey); // Log the expected admin key
  console.log('Entered License Key: ', licenseKey); // Log the entered license key

  if (licenseKey === adminKey) {
    console.log('License key is correct! Redirecting to admin...');
    onLogin(); // Authenticate and proceed to admin page
    navigate('/admin'); // Redirect to admin after successful login
  } else {
    console.log('Invalid License Key');
    alert('Invalid License Key');
  }
};

  return (
    <div className="login-container">
      <div className="content-wrapper">
        <img src="https://i.imgur.com/bczO6FR.jpeg" alt="Ragnarok Logo" className="logo" />
        <h1 className="title">Ragnarok Alliance</h1>
        <p className="slogan">Bound by War, Driven by Honor</p>
        <form onSubmit={handleSubmit} className="login-form">
          <input
            type="text"
            value={licenseKey}
            onChange={(e) => setLicenseKey(e.target.value)}
            placeholder="Enter License Key"
            required
            className="license-input"
          />
          <button type="submit" className="submit-button">Submit</button>
        </form>
      </div>
    </div>
  );
}

export default LoginPage;
