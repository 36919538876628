import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Import axios to handle API requests
import './AdminPage.css';

function AdminPage() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false); // To handle loading state

  // Fetch users from the backend on component mount
  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const response = await axios.get('https://damp-sea-24296-802ba97e7bbf.herokuapp.com/users');
        setUsers(response.data); // Assuming the backend returns a list of users
      } catch (error) {
        console.error('Error fetching users:', error);
      }
      setLoading(false);
    };

    fetchUsers();
  }, []);

  // Update remaining time every second and check if the shield has expired
  useEffect(() => {
    const interval = setInterval(() => {
      setUsers((prevUsers) =>
        prevUsers.map((user) => {
          const remainingTime = user.expirationTime - Date.now();
          return {
            ...user,
            remainingTime: remainingTime > 0 ? remainingTime : 0,
            shieldStatus: remainingTime > 0 ? 'Active' : 'Expired',
          };
        })
      );
    }, 1000);

    return () => clearInterval(interval);
  }, [users]);

  // Format time for the countdown timer
  const formatTime = (time) => {
    const hours = Math.floor(time / 3600000);
    const minutes = Math.floor((time % 3600000) / 60000);
    const seconds = Math.floor((time % 60000) / 1000);
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  // Reset shield and update the backend
  const handleResetShield = async (id) => {
    try {
      const response = await axios.patch(`https://damp-sea-24296-802ba97e7bbf.herokuapp.com/users/${id}`, {
        shieldTime: '0h',
        shieldStatus: 'Expired',
        expirationTime: 0,
      });
      setUsers(users.map(user => user._id === id ? response.data : user));
    } catch (error) {
      console.error('Error resetting shield:', error);
    }
  };

  // Update shield time and send the update to the backend
  const handleUpdateShield = async (id, newShieldTime) => {
    try {
      const expirationTime = Date.now() + newShieldTime * 3600 * 1000;
      const response = await axios.patch(`https://damp-sea-24296-802ba97e7bbf.herokuapp.com/users/${id}`, {
        shieldTime: `${newShieldTime}h`,
        shieldStatus: 'Active',
        expirationTime,
      });
      setUsers(users.map(user => user._id === id ? response.data : user));
    } catch (error) {
      console.error('Error updating shield:', error);
    }
  };

  if (loading) return <p>Loading...</p>;

  return (
    <div className="admin-container">
      <img src="https://i.imgur.com/bczO6FR.jpeg" alt="Ragnarok Alliance" className="logo" />
      <h1 className="title">Ragnarok Alliance</h1>

      <div className="tracker-header">
        <h2 className="subtitle">R4/R5 Admin Panel</h2>
        <button className="back-button" onClick={() => window.location.href = '/'}>Back</button>
      </div>

      <p className="slogan">Manage Shielding for All Users</p>

      <div className="admin-section">
        <h3>User Shield Status</h3>
        <table className="user-table">
          <thead>
            <tr>
              <th>User</th>
              <th>Shield Time</th>
              <th>Countdown</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.map(user => (
              <tr key={user._id}>
                <td>{user.name}</td>
                <td>{user.shieldTime}</td>
                <td>{formatTime(user.remainingTime)}</td>
                <td>
                  <span className={`status ${user.shieldStatus.toLowerCase()}`}>
                    {user.shieldStatus}
                  </span>
                </td>
                <td>
                  <button onClick={() => handleUpdateShield(user._id, 16)} className="update-button">Update Shield</button>
                  <button onClick={() => handleResetShield(user._id)} className="reset-button">Reset Shield</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AdminPage;
