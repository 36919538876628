import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LoginPage from './LoginPage';
import ShieldInputPage from './ShieldInputPage';
import AdminPage from './AdminPage';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Track authentication state

  // Function to handle login and save authentication status
  const handleLogin = () => {
    setIsAuthenticated(true); // Set to true when the user logs in
    localStorage.setItem('isAuthenticated', 'true'); // Save auth status to localStorage
  };

  // On page load, check localStorage for authentication
  useEffect(() => {
    const authStatus = localStorage.getItem('isAuthenticated');
    if (authStatus === 'true') {
      setIsAuthenticated(true);
    }
  }, []);

  return (
    <Router>
      <Routes>
        {/* Redirect to login if not authenticated */}
        <Route 
          path="/" 
          element={isAuthenticated ? <ShieldInputPage /> : <Navigate to="/login" />} 
        />
        <Route 
          path="/admin" 
          element={isAuthenticated ? <AdminPage /> : <Navigate to="/login" />} 
        />
        <Route 
          path="/login" 
          element={<LoginPage onLogin={handleLogin} />} 
        />
      </Routes>
    </Router>
  );
}

export default App;
