import React, { useState, useEffect } from 'react';
import './ShieldInputPage.css';

function ShieldInputPage() {
  const [username, setUsername] = useState('');
  const [shieldHours, setShieldHours] = useState(''); // Time in hours
  const [remainingTime, setRemainingTime] = useState(0); // Time in milliseconds
  const [users, setUsers] = useState([]);
  const [notificationSettings, setNotificationSettings] = useState({
    notifyTime: 0, // in hours
    viber: false,
    whatsapp: false
  });

  useEffect(() => {
    // Load the list of users from localStorage
    const storedUsers = JSON.parse(localStorage.getItem('users')) || [];
    setUsers(storedUsers);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (remainingTime > 0) {
        setRemainingTime(prevTime => prevTime - 1000); // Decrease time by 1 second
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [remainingTime]);

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600000);
    const minutes = Math.floor((time % 3600000) / 60000);
    const seconds = Math.floor((time % 60000) / 1000);
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  // Define the getColor function to return the correct color based on remaining time
  const getColor = () => {
    const hoursRemaining = remainingTime / (1000 * 60 * 60); // Convert ms to hours
    if (hoursRemaining > 5) return 'green';
    if (hoursRemaining > 3) return 'yellow';
    if (hoursRemaining > 1) return 'orange';
    return 'red';
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setRemainingTime(shieldHours * 3600 * 1000); // Update timer

    const expirationTime = Date.now() + shieldHours * 3600 * 1000;

    const newUser = {
      id: users.length + 1, // Unique ID for each user
      name: `[RGK]${username}`,
      shieldTime: `${shieldHours}h`,
      expirationTime, // Store expiration timestamp
      shieldStatus: 'Active'
    };

    const updatedUsers = [...users, newUser]; // Append the new user to the list
    setUsers(updatedUsers);

    // Save users to localStorage
    localStorage.setItem('users', JSON.stringify(updatedUsers));

    // Reset form fields
    setUsername('');
    setShieldHours('');
  };

  const handleNotificationSubmit = (e) => {
    e.preventDefault();
    alert('Notification settings updated!');
  };

  return (
    <div className="shield-input-container">
      <img src="https://i.imgur.com/bczO6FR.jpeg" alt="Ragnarok Alliance" className="logo" />
      <h1 className="title">Ragnarok Alliance</h1>
      
      <div className="tracker-header">
        <h2 className="subtitle">Shield Tracker</h2>
        <button className="admin-button" onClick={() => window.location.href = '/admin'}>R4/R5 Panel</button>
      </div>

      <p className="slogan">Real-Time Shielding for Real-Time Success</p>

      <div className="countdown">
        <h2>Countdown</h2>
        <div className="timer" style={{ color: getColor() }}>
          {formatTime(remainingTime)}
        </div>
      </div>
      
      <form className="shield-form" onSubmit={handleSubmit}>
        <label className="input-label">
          Username:
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Enter username"
            className="input-field"
            required
          />
        </label>
        <label className="input-label">
          Shield Time (hours):
          <input
            type="number"
            value={shieldHours}
            onChange={(e) => setShieldHours(e.target.value)}
            min="1"
            className="input-field"
            placeholder="Enter hours"
            required
          />
        </label>
        <button type="submit" className="update-button">Update Shield</button>
        <p className="instruction-text">Please enter the shield duration in hours only.</p>
      </form>
      
      <form className="notification-settings" onSubmit={handleNotificationSubmit}>
        <h2>Notification Settings</h2>
        <label className="input-label">
          Notify me when shield time is:
          <input
            type="number"
            name="notifyTime"
            value={notificationSettings.notifyTime}
            onChange={(e) => setNotificationSettings({ ...notificationSettings, notifyTime: e.target.value })}
            min="0"
            className="input-field"
            placeholder="Enter hours"
          />
          hours
        </label>
        <label className="checkbox-label">
          <input
            type="checkbox"
            name="viber"
            checked={notificationSettings.viber}
            onChange={(e) => setNotificationSettings({ ...notificationSettings, viber: e.target.checked })}
            className="checkbox"
          />
          Viber
        </label>
        <label className="checkbox-label">
          <input
            type="checkbox"
            name="whatsapp"
            checked={notificationSettings.whatsapp}
            onChange={(e) => setNotificationSettings({ ...notificationSettings, whatsapp: e.target.checked })}
            className="checkbox"
          />
          WhatsApp
        </label>
        <button type="submit" className="notify-button">Save Settings</button>
      </form>
    </div>
  );
}

export default ShieldInputPage;
